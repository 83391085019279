<template>
  <div>
    <div class="cont">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <ul id="menu">
          <li id="menu01">
            <router-link to="/client-info_company"
              ><span>企業情報修正</span></router-link
            >
          </li>
          <li id="menu02">
            <router-link to="/client-info_employee"
              ><span>社員情報追加・修正</span></router-link
            >
          </li>
          <li id="menu03">
            <a @click="check_request"><span>ストレスチェック依頼</span></a>
          </li>
          <li id="menu04">
            <router-link to="/client-change_password"
              ><span>パスワード変更</span></router-link
            >
          </li>
          <li id="menu05">
            <a @click="$router.push('client-stress_check_history')"
              ><span>ストレスチェック受診歴</span></a
            >
          </li>
          <li class="menu0d"></li>
        </ul>
        <button
          class="uk-button uk-button-secondary uk-width-1-1 w90"
          type="button"
          name="button"
          @click="logout()"
        >
          ログアウト
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu",
  data() {
    return {
      status: "",
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_c;
    this.checkLogined();
    this.$store.state.common.sDataLoading = false;
    this.$store.state.sc_id = 0;
  },
  methods: {
    check_request() {
      this.$axios
        .post("/client/get_simple_requests", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then((response) => {
          console.log(response);
          if (response.data.flg == true) {
            this.$router.push("/client-request_lists");
          } else {
            this.$router.push("/client-request_stresscheck");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/client-login");
      }
    },
    logout() {
      this.$store.commit("setLogout_c", {});
      sessionStorage.removeItem("email_c");
      sessionStorage.removeItem("pass_c");
    },
  },
  watch: {
    "$store.state.login_status_c"(newVal) {
      //eslint-disable-line
      if (newVal == false) {
        this.$router.replace("/client-login");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
